@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url('https://fonts.googleapis.com/css?family=Poppins'); */
/* @font-face {
    font-family: 'Bungee Inline', cursive;
    src: url('https://fonts.googleapis.com/css?family=Poppins')
} */

/* *{
  font-family:"Noto Sans" !important
} */


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

#menu-appbar ul{
  width:180px;
}
#menu-appbar .MuiPaper-root {
    right: 15px !important;
    left: unset !important;
    top: 60px !important;
}
#menu-appbar ul.MuiMenu-list li {
    box-shadow: 0 1px 0 0 #e6e6e6;
    font-family: 'Noto Sans';
    font-size: 14px;
    font-weight: bold;
}#menu-appbar ul.MuiMenu-list li:last-child {
  box-shadow: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #ffffff; */

}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#e0e0e0;
  border-radius:3px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/**{*/
  /*font-family: NotoSans,"sans-serif"!important;*/
/*}*/

.popover{
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
}

.popover-header{
  background-color: #ffffff;
}

.geosuggest__input{
  height: 45px;
  width: 240px;
  border:solid 1px #b4b3b3;
  border-radius: 3px;
  padding:.5em 1em;
  color:'#707070';
}

.geosuggest__suggests{
    padding: 5px 0;
    margin: 2px 0 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    font-size: 14px;
    text-align: inherit;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    overflow:scroll;
}
.geosuggest__suggests li {
    margin: 10px 0px 10px 0px;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    padding:0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item--active {
    background: #267dc0;
    color: #267dc0;
}

.pac-container {
    z-index: 1301 !important;
}

/*Schedular Css Starts Below*/
.dragging{
  cursor:grab
}
.dragging:hover .normalView{
  display:flex !important;
}
.dragging:hover .hoveredView{
  display:none !important;
}
/* .duringDrag{
  height:52px;
  border:1px dashed;
} */
.duringDrag .dragging{
  border:1px dashed #bebbbb;
  border-radius:4px;

}
.duringDrag .normalView{
  opacity:0;
}
.scheduler td {
  padding: 0px;
  vertical-align: top;
}
#RBS-Scheduler-root{
  position: relative;
  margin:0;
  top: -2px;
  background: #fff;
}

.header2-text {
  z-index: 99999;
  height: 100% !important;
  top: 0 !important;

}
.header2-text div:first-child{
  position: relative;
  width: 100%;
  height: 100% !important;
  top: 0;
  right: 0;
  margin: 0 !important;
} 

.resource-table .header3-text{
  background: #f8f8f8;
  text-indent: 102%; 
  white-space: nowrap;
  overflow: hidden;
  /* position: fixed; */
  z-index: 99;
  width: 451px;
  height: 76px !important;
  top: 138px;
  border: 1px solid #e9e9e9;
}
.resource-table tbody tr:first-child td{
  min-height: 52px !important;
}
.scheduler-content .scheduler-content-table tbody tr:first-child td .event-container div .timeline-event{
  height:51px
}
.scheduler-view div:first-child div div .scheduler-bg-table:first-child tr{
  /* position: fixed; */
  width: auto;
  display: flex;
  justify-content: flex-start;
  top:138px;
  z-index: 999;
}
.header3-text {
  height: 86px !important;
}
/* .scheduler-bg-table tr td{
  border-bottom: none !important;
} */
table.resource-table tr th:last-child, table.scheduler-table tr th:last-child, table.resource-table tr td:last-child{
  border-bottom: 1px solid #e9e9e9 !important;
}

.resource-table tbody tr:nth-child(1) td, .resource-table tbody tr:nth-child(2) td{
  background:rgba(145, 192, 247, 0.17)
}

/* .scheduler-content .scheduler-content-table tbody tr:nth-child(1), .scheduler-content .scheduler-content-table tbody tr:nth-child(2){
  background: rgba(145, 192, 247, 0.17);
} */

.resource-view > div:first-child, .scheduler-view > div:first-child{
  border-bottom: none !important;
}
/* .scheduler-bg-table tr:nth-child(4) td{
  background-image: unset;
} */
/*Schedular Css End Here*/

/* Add More popover css starts here */
.add-more-popover-overlay{
  padding: 13px 15px 12px;
  max-height:300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.add-more-popover-closeBtn{
  height: 20px !important;
  margin-left: 6px;
  width: 100% !important;
}
/* .add-more-popover-overlay > div:first-child{
  height: 0;
  width: 100%;
}
.add-more-popover-overlay > div:first-child i{
  display: none;
}
.add-more-popover-overlay > div:first-child{
  position: absolute;
  bottom: 12px;
  left: 15px;
} */
.expand-less-unmanned{
  font-family: "Noto Sans";
  color: #006BFF;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.expand-less-unmanned > svg{
  font-size: 19px;
}
/* .add-more-popover-overlay > div:first-child > div:first-child{
  display: none
}
.add-more-popover-overlay > div:first-child > div:nth-child(2){
  position: absolute;
  width: calc(100% - 30px);
  height: 18px;
  bottom: -5px;
} */
.add-more-popover-overlay > div{
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  height: 50px;
}
.add-more-popover-overlay > div > .timeline-event{
  top: 0 !important;
  left: 0 !important;
}
.add-more-expand{
  margin: 0 5px;
  font-weight: 700
}
.add-more-expand > svg{
  font-size: 19px;
}
/* Add More popover css ends here */

/*Color picker css*/
.twitter-picker,.sketch-picker,.chrome-picker {
  position: absolute !important;
  top: 50px;
  z-index: 99;
}

/*React Emoji Mart*/

.emoji-mart-scroll {
    border-bottom: 0;
    margin-bottom: 6px;
}

.emoji-mart-scroll + .emoji-mart-bar {
    display: none;
}

/*React Flag Select Phone Number*/

.flag-select img {
    width: 1.3em;
    height: 1.3em;
    position: initial;
    top: 0.3em;
    font-size: 20px;
}

.flag-select .selected--flag--option {
    cursor: pointer;
    padding-top: 6px;
    font-size: 20px;
}

.flag-select .arrow-down {
    color: #4d4d4d;
    padding: 2px;
    position: absolute;
}

.flag-select .flag-options {
    font-size:14px;
    font-family: Noto Sans;
    min-width:14em;
    overflow-x: hidden;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16), 0 4px 9px 0 rgba(0, 0, 0, 0.2);
}

.flag-select .filterBox input {
    height: 33px;
    font-family: Noto Sans;
    border-radius: 5px;
}
@media print{
  /* *{
    visibility:hidden;
  }
  table#RBS-Scheduler-root{
    visibility: visible !important;
    position:absolute;
    top:0;
    left:0;
    right:0;
  }
  table#RBS-Scheduler-root *{
    visibility: visible !important;
  } */
  @page {
    size: auto;
  }
}

@-webkit-keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}

@keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}

.button {
  border: 0;
  padding: 8px 10px;
  min-width: 120px;
  border-radius: 5px;
  color: #ffffff;
  font-family: Noto Sans, sans-serif;
  background-color: #006BFF;
}.location-wrap .geosuggest__label{
  display:  none !important;
}.location-wrap .geosuggest__input{
  width: 100% !important;
}.addMore{
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: 600;
  text-align: right;
  color: #006BFF;
  margin-right: 7px;
}.new-fields{
  margin: 0 0 0.5rem;
  display: flex;
  position: relative;
}.new-fields .geosuggest, .new-fields input{
  width: 98% !important;
}.text-right{
  text-align: right;
}
